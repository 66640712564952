<template>
  <div
    class="relative flex transition lg:mr-4 lg:border-r-2 lg:pr-4 2xl:mr-8 2xl:pr-8"
    :class="{
      'lg:border-white': isNavbarTransparentVisible,
      'lg:border-primary': !isNavbarTransparentVisible
    }"
  >
    <slot name="beforeLink" />

    <component
      :is="as ?? CommonLink"
      class="flex items-center py-1 transition lg:p-0"
      v-bind="linkAttributes"
      @click="$emit('linkClick')"
    >
      <span class="flex">
        <slot name="icon" />
      </span>

      <span class="link-text ml-2 hidden lg:ml-3.5 lg:block">
        <slot />
      </span>
    </component>

    <slot name="afterLink" />
  </div>
</template>

<script lang="ts" setup>
import CommonLink from '@autobid/ui/components/common/Link.vue'

interface Props {
  linkAttributes?: Record<string, string>
  as?: 'a' | 'button'
}

defineEmits(['linkClick'])
defineProps<Props>()

const isNavbarTransparentVisible: boolean = inject('isNavbarTransparentVisible')
</script>

<style lang="scss">
@media (max-width: 1023px) {
  .top-bar-profile-dropdown {
    li {
      & > {
        a,
        button,
        span {
          width: 100%;
          color: var(--color-primary) !important;
          padding: 4px 9px;
        }

        button,
        a {
          &:hover {
            background: var(--color-primary);
            color: #fff !important;
          }
        }
      }
    }

    .link-text {
      display: block;
    }

    svg {
      width: 20px;
      height: 20px;
    }
  }
}
</style>
